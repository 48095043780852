import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This week, `}<a parentName="p" {...{
        "href": "https://www.dashbot.io"
      }}>{`Dashbot`}</a>{` hosted a meetup focused on designing for conversational interfaces featuring industry experts from TD Ameritrade, the American Red Cross, Travelers Insurance, and Skilled Creative.`}</p>
    <p><strong parentName="p">{`Panelists included:`}</strong></p>
    <ul>
      <li parentName="ul">{`Arte Merritt (moderator), CEO/Co-founder, `}<a parentName="li" {...{
          "href": "https://www.dashbot.io"
        }}>{`Dashbot`}</a></li>
      <li parentName="ul">{`Angela Novosel, Senior Product Manager, `}<a parentName="li" {...{
          "href": "https://www.tdameritrade.com/"
        }}>{`TD Ameritrade`}</a></li>
      <li parentName="ul">{`Brandon Kaplan, CEO, `}<a parentName="li" {...{
          "href": "https://www.skilledcreative.com/"
        }}>{`Skilled Creative`}</a></li>
      <li parentName="ul">{`Michelle Mullenax, Product `}{`&`}{` UX Lead Innovation, `}<a parentName="li" {...{
          "href": "https://www.redcross.org/"
        }}>{`American Red Cross`}</a></li>
      <li parentName="ul">{`Tim Carlson, Senior Director of Product Management – AI, `}<a parentName="li" {...{
          "href": "https://www.travelers.com/"
        }}>{`Travelers Insurance`}</a></li>
    </ul>
    <p>{`The panel provided valuable insights and tips on developing for conversational interfaces, including effective usecases, development and testing best practices, and tips to improve user acquisition and discovery.`}</p>
    <h2>{`Why build a chatbot or voice skill?`}</h2>
    <p>{`Our panelists are building chatbots and voice skills for a variety of reasons – from reducing costs, to providing more effective ways to communicate, offering quicker interactions, as well doing things that stand out – generating awareness.`}</p>
    <p>{`TD Ameritrade’s goal is to provide content that is informative and helpful and enable client services, like making a trade.`}</p>
    <p>{`American Red Cross has a wide variety of use cases – from blood and financial donations to humanitarian services, services for the armed forces, training services, and more. Their current chatbots and voice skills enable scheduling blood donations, first aid training, and hurricane alerts. When building a chatbot or voice skill, they consider three criteria: (1) the mission – is the chatbot serving the Red Cross’ mission and delivering services in a more efficient way; (2) automation / cost savings; (3) revenues – for example, is the chatbot able to bring in more blood donations or financial donations.`}</p>
    <p>{`Travelers is building both internal and external chatbots for customers and employees. On the customer side, the focus is on providing a more frictionless experience – enabling customers to get information or do something (e.g. file a claim) faster and easier, on demand. For internal chatbots, Travelers is enabling employees to get information much faster.`}</p>
    <p>{`Skilled Creative builds for a wide variety of use cases, depending on the client – including use cases that are more utilitarian as well as ones for consumer engagement, conversions/sales, and brand awareness. They apply a strategic approach – overall, trying to define use cases that will stand out on the platforms – unique value propositions that will get initial engagement, will have value, and can create retention (loyalty).`}</p>
    <h2>{`What use cases work well for chatbots or voice skills?`}</h2>
    <p>{`We asked our panelists what use cases work well for chatbots and voice skills – and when to build for those platforms. Context, inputs, and the goals are important for deciding on the right platform.`}</p>
    <p>{`The panelists were fairly in agreement on when to build for text based chatbots versus voice. If the experience requires complex flows, entering a lot of information, or providing personal information, or visuals, chatbots are the better fit. If the experience is more transactional, and speed of response is important, or the use case is more for entertainment, then voice skills are better options.`}</p>
    <p>{`Skilled Creative is typically a voice first agency for consumer facing experiences. They are very passionate about the voice channel. Brandon cited the reach and distribution of the devices – being on users counters and bedsides – as well as the layers of friction being reduced and the immediacy of the responses. While they are voice first, they do see needs to escalate to a chatbot or even cases where a chatbot might be more effective to start – e.g. for complex inputs of lots of information.`}</p>
    <p>{`Tim at Travelers had similar feedback – using voice skills for more immediate responses and chatbots for ones that require complex flows and more information. For example, an insurance claim may require 15 pieces of information – providing those via voice may not be the best solution.`}</p>
    <p>{`Michelle at American Red Cross also takes a similar approach – they are channel agnostic. They take each use case and see if it makes more sense on one channel versus the other. Ones that are too complex, require lots of text, or have anything visual, are better suited for chatbots. Voice skills are meant to be short and sweet. Michelle recommends looking at the depth of the experience to see which channel is actually the best fit, and then have the discipline to go in that direction.`}</p>
    <p>{`For Angela and TD Ameritrade, the feedback was quite the same. They use voice skills for information and entertainment – “snackable” moments. Anything that involves more complicated data, is better suited for a chatbot.`}</p>
    <h2>{`What are some best practices for building conversational interfaces?`}</h2>
    <p>{`Our panelists had great insights and tips for best practices in conversational design.`}</p>
    <h3>{`Design for the channel`}</h3>
    <p>{`Michelle pointed out not to assume all the channels are the same – they are unique – don’t treat your chatbot the same as a voice skill in terms of conversational design. Users don’t type to a chatbot the same way they speak to an Alexa, nor do they communicate the same way with an IVR. While you can use a tool like Dialogflow across all your channels, the experience model should be unique to the medium. It’s important to test these user experiences too.`}</p>
    <h3>{`Start small`}</h3>
    <p>{`As Tim pointed out, it’s better to start small. Some folks make the mistake of thinking too big to start and trying to build a chatbot that covers everything. If users don’t go down the “happy path,” it’s probably not going to work out too well.`}</p>
    <h3>{`Be willing to change your existing processes`}</h3>
    <p>{`What works on the web or mobile, might not work well in a conversational interface. For example, Travelers may have users answer a bunch of yes/no questions in an IVR flow, but that same experience would be frustrating in a chatbot. Similarly, Michelle mentioned pushing back on the business to be “conversation friendly” – the copy on the website may not work well in the chatbot. It’s important to understand the channels are different and might require changes to existing flows, content, and processes.`}</p>
    <h3>{`Context is key`}</h3>
    <p>{`As Brandon pointed out, at Skilled Creative, they look to see if the user journey is progressive and contextual. If a user comes back, that is great for retention, but is the conversation progressing? It’s important to understand how many times the user has returned and where they left off in the flow. For example, rather than have the same long intros over and over again, reduce those for the returning user.`}</p>
    <h3>{`Be creative, but do not go overboard`}</h3>
    <p>{`Brandon’s team aims to provide a creative experience, but one that serves a purpose for the user experience, not just for their own self-indulgence. According to Brandon, each time they put too much copy and too many witty remarks, they ended up confusing the user and their goal completion rates suffered. On the creative note, they found employing screenwriters and comedians can help with content writing – comedians in particular know timing quite well.`}</p>
    <h3>{`Leverage user testing and analytics`}</h3>
    <p>{`Prior to launch, test your voice skill or chatbot; after launch, monitor the analytics and iterate.`}</p>
    <p>{`Each of our panelists makes use of user testing prior to launch. It ranges from internal employee testing, to volunteer and customer testing, as well as using third party vendors like Applause and Pulse Labs.`}</p>
    <p>{`After launch, our panelists are looking at the analytics and transcripts and iterating on the chatbots. For chatbots and voice skills using NLP, it’s important to look at the fallback Intents and see what is leading to those occurrences – where the chatbot is breaking down in not understanding the user or responding correctly. As Brandon pointed out, while user testing is important prior to launch, the analytics after launch are even more important.`}</p>
    <h2>{`What are best practices for user acquisition?`}</h2>
    <p>{`User acquisition in chatbots and voice skills is still a challenge. It’s a relatively early space and users may not even know they exist or may need education on how to use a chatbot or voice skill. In our own `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/2018/11/19/how-consumers-really-use-alexa-and-google-home-voice-assistants/"
      }}>{`survey of Alexa and Google Home users`}</a>{`, many did not even know what voice applications were called, never mind how, or where, to find them. `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/2017/02/01/user-acquisition-monetization/"
      }}>{`In a previous panel`}</a>{`, we heard from other industry experts who recommended video influencer campaigns as the influencers not only provided reach, but the videos showed how to use and interact with the chatbot. As Angela pointed out, part of the acquisition is the education of users to know they can interact with a brand in this conversational way.`}</p>
    <p>{`For TD Ameritrade, one avenue that works well is financial education conferences. Making attendees aware of the chatbots and helping educate on how to use them.`}</p>
    <p>{`The American Red Cross is fortunate to have a loyal following of volunteers and donors to reach out to, however they have to be mindful of all the other competing marketing initiatives for blood donations, fundraising, and more. While there is a possibility to promote the voice skills in weekly emails, if there is a major disaster event, they won’t be able to promote the skills. The skills are relatively new so they are still studying adoption rates.`}</p>
    <p>{`Travelers is currently taking a conservative approach to marketing. While they have announced a partnership with Amazon, and have public Skills, they purposely are taking a slower approach. They want to make sure the user experience is working they way they want, and then they will blast it out to the general public.`}</p>
    <p>{`For Skilled Creative, marketing and user acquisition is a big part of what they are hired to do. Brandon stressed the importance of creating a marketing plan and executing on it. He shared a number of helpful tips:`}</p>
    <p><strong parentName="p">{`Lean on the underlying platform`}</strong>{`. Build relationships with Google and Amazon. When the launch is approaching, reach out to them with enough lead time, sharing how the voice skills is doing, and they can help promote you on their website, emails, social media, and potentially even on the device (e.g. the screen of the Echo Show).`}</p>
    <p><strong parentName="p">{`Leverage your own properties`}</strong>{`. If you are a brand with websites or mobile apps, those are opportunities to promote the chatbot and voice skills.`}</p>
    <p><strong parentName="p">{`Create a strategic social media initiative`}</strong>{`. Remember to take into account context and medium. If you are promoting a chatbot, think of a highly visual/clickable placement. If you are building for voice, think about well placed wake phrases.`}</p>
    <h2>{`What does the future hold for chatbots and voice skills?`}</h2>
    <p>{`We asked our panelists if anything needs to occur for chatbots and voice skills to take off more. Chatbots and voice skills are still a relatively new space. As our panelists pointed out, the industry still needs to mature. We are still in the early days, especially with voice apps and perhaps haven’t yet fully taken advantage of the platform. If you recall, the initial iPhone apps tended to be rather silly and it was later that we saw the true utility apps.`}</p>
    <p>{`In the case of voice, or more specifically Alexa, Brandon pointed out how there is a bit of a content issue, which while it is a problem, it’s also a great opportunity for companies. When uses ask Alexa for information it does not know, users may get frustrated and not see the full utility. These are opportunities though for brands and developers to provide the missing content through Skills, and help make the device much more useful as a knowledge source, which in turn can endear more people to the platform.`}</p>
    <p>{`Tim brought up improvements in chatbots and voice skills ability to more accurately respond. We are seeing NLP engines getting better everyday. As they continue to improve, and chatbots continue to improve in understanding what users are asking and responding appropriately, the space will take off even more. He envisions a future were everything will be on demand, which we are already starting to see signs of with Alexa in the car, appliances, home security, and more. Angela also sees a future with more integrations to reach customers on every channel they want to be reached.`}</p>
    <p>{`While we talk about the future, Michelle made a great point of getting started now. The Red Cross deals with life saving initiatives. They cannot afford to play catch up on a platform. They rather be early, even if it has lower adoption, while the industry catches up, rather than scramble to get something out when the industry is more mature.`}</p>
    <p>{`At Dashbot, we are very excited about the future of chatbots and voice skills. We believe conversation is the natural way to communicate and look forward to seeing all the use cases brands and developers come up with in the future.`}</p>
    <h2>{`Watch the full panel`}</h2>
    <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/n7Tly-XQxoc?feature=oembed" title="Dashbot NYC Meetup - Designing for Conversational Interfaces" width="1200"></iframe>
    <h2>{`About Dashbot`}</h2>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "https://www.dashbot.io"
        }}>{`Dashbot`}</a>{` is a conversational analytics platform that enables enterprises and developers to understand user behaviors, optimize response effectiveness, and increase user satisfaction through actionable data and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including NLP response effectiveness, sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and broadcast messaging for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support Alexa, Google Home, Facebook Messenger, Slack, Twitter, Kik, SMS, web chat, and any other conversational interface.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      